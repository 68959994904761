/*
* Styling for the Project Progress page
*/

.project-progress-hero-unit {
  height: 205px;
  background-color: #912842;
  margin-top: 100px;
  margin-bottom: 65px;
}

.project-progress-hero-unit h2 {
  margin-top: 80px;
  color: #fff;
  //text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 1px;
}

.flex-caption {
  position:absolute;
  width: 100%;
  height: inherit;
  padding: 2%;
  left: 0;
  bottom: 0;
  background: #912842;
  opacity: 0.8;
  color: #ffffff;
  text-shadow: 0 -1px 0  #000000;
  text-align: left;
  font-size: 30px;
  line-height: 30px;
}