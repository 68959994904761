/*
* Front Side CSS
*/
/* Front Side font type*/
* {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

.row, .top-bar {
  max-width: 960px;
}

p {
  color: #706c6c;
}

/* Alert Button*/
div.alert {
  padding: 20px;
  z-index: 10;
}

div.alert .close {
  display: none;
}

div.alert:hover {
  opacity: 1;
}

.alert-success {
  background: #2ad17d;
  color: #fff;
}

.alert-info {
  background: #3374B7;
  color: #fff;
}

.alert-danger {
  background: #ff3860;
  color: #fff;
}

.alert-error {
  background: #ff3860;
  color: #fff;
}

.alert-warning {
  background: #0a0a0a;
  color: #ffdd57;
}

.label-eye {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.label-eye.primary {
  color: #3374B7;
}

.label-eye.gray {
  color: #AEB6BD;
  background: #AEB6BD;
}

.label-eye.success {
  color: #2ad17d;
}

.label-eye.alert {
  color: #ff3860;
}

.label-eye.warnings {
  background: #ffcb00;
}

.intranet-success {
  color: #fff;
  background: #2ad17d;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 1px;
}

.intranet-success:hover, .intranet-success:active, .intranet-success:focus {
  color: #fff;
  border: 1px solid #2ad17d;
  background: #2ad17d;
}

.intranet-alert, .intranet-danger {
  color: #fff;
  background: #ff3860;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 1px;
}

.intranet-alert:hover, .intranet-alert:active, .intranet-alert:focus, .intranet-danger:hover, .intranet-danger:active, .intranet-danger:focus {
  color: #fff;
  border: 1px solid #ff3860;
  background: #ff3860;
}

.intranet-warning {
  color: #fff;
  background: #ff9100;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 1px;
}

.intranet-warning:hover, .intranet-warning:active, .intranet-warning:focus {
  color: #fff;
  border: 1px solid #ff9100;
  background: #ff9100;
}

span.error {
  color: #ff3860;
  font-size: 12px;
  font-style: italic;
}

input.error, select.error {
  border: 1px solid #ff3860;
}

#Ct-Table .intranet-success, #Ct-Table .intranet-alert {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 115px;
}

/*
*  Formatting for all front side buttons
*/
input[type=submit], .button {
  border-radius: 0;
  box-shadow: none;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 16px;
}

h2 {
  font-size: 23px;
  line-height: 48px;
  color: #912842;
  margin-bottom: 30px;
}

/*
*  Formatting for all front side input fields
*/
input:not([type]), input[type=text], input[type=password], input[type=date], input[type=datetime], input[type=datetime-local], input[type=month], input[type=week], input[type=email], input[type=number], input[type=search], input[type=tel], input[type=time], input[type=url], input[type=color], textarea {
  border-style: none;
  border-radius: 3px;
  border-width: 0;
  border-color: #fff;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.75);
}

/* Custom Buttons */
.custom-button {
  background-color: #912842;
  text-transform: uppercase;
}

.custom-button:hover {
  background-color: #7f3b4b;
}

.hollow-button {
  transition: background-color .9s ease;
  border: 1px solid #912842;
  border-radius: 0;
  padding: 14px 40px 14px 40px;
  color: #912842;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 16px;
  background-color: #fff;
  text-transform: uppercase;
}

.hollow-button:hover {
  transition: background-color .9s ease;
  border: 1px solid #912842;
  color: white;
  background-color: #912842;
}

.classical-button {
  background-color: #912842;
  text-transform: uppercase;
}

.dark-button {
  background: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  border: 1px solid #fff;
}

.dark-button:hover {
  background-color: black;
}

.reveal-modal .close-reveal-modal {
  color: #232336;
  top: -4px;
  right: 1.375rem;
}

/*
* Slider styling
*/
.slick-slide {
  position: relative;
}

.slick-prev, .slick-next {
  display: none !important;
}

@media screen and (max-width: 39.9375em) {
  h2 {
    margin-bottom: 15px;
  }
}

/*
* Medium screens styling
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  h2 {
    margin-bottom: 15px;
  }
  .landing-container #boxholder {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
