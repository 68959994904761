/*
* Styling for the Article page
*/
.article-hero-unit {
  height: 95px;
  background-color: #ededec;
  margin-bottom: 65px;
}

.article-hero-unit h2 {
  margin-top: 25px;
  color: #912842;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 1px;
  font-weight: 500;
}

.article-header {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.article-header h2 {
  margin-bottom: 0px;
}

.article-header p {
  margin-bottom: 0px;
  font-size: 14px;
}

.article-info {
  margin-bottom: 65px;
}

.article-info .mcnTextContent p,
.article-info .mcnTextContent a,
.article-info .mcnTextContent li,
.article-info .mcnTextContent strong {
  font-size: 25px !important;
  line-height: 1.6em !important;
}

.article-info .mcnTextContent strong {
  color: #606060;
}

.article-info .title {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.article-info .table {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95% !important;
  border-collapse: collapse;
}

.article-info .table thead tr td,
.article-info .table .header td {
  background-color: #006666 !important;
  color: #FFF !important;
}

.article-info .table thead tr td p strong, .article-info .table thead tr td p,
.article-info .table .header td p strong, .article-info .table .header td p {
  font-weight: normal !important;
  color: #FFF !important;
}

.article-info .table img {
  margin-left: 30% !important;
  text-align: center !important;
  height: 32px;
}

.article-info .table tr td {
  border: 1px solid black;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.article-info .table p {
  vertical-align: center !important;
}

.article-info .table tr td > :first-child {
  vertical-align: center !important;
}

.article-info .table-striped tbody > tr:nth-of-type(even) {
  background-color: #EAF2D3 !important;
}

.article-info .table-striped tbody > tr:nth-of-type(odd), .article-info .footer {
  background: none;
}

.article-info .table-footer {
  background-color: #FFF !important;
}

.article-info .table tfoot td {
  border: none;
  padding: 0px;
  padding: 0px;
  padding-left: 5px;
  margin: 0px;
}

.article-info .table tfoot {
  border: 1px solid #000;
}

.article-info .table tfoot td p {
  margin: 0.5em;
  font-size: 0.9em !important;
  line-height: 1em !important;
}

.stripe {
  background-color: #EAF2D3;
}

.yellow {
  background-color: yellow !important;
}

.gray {
  background-color: #e3e3e3;
}

.hold {
  background-color: #e3e3e3;
}

.hold p {
  text-align: center !important;
}

.green, .buy {
  background-color: #27A027 !important;
}

.green p, .buy p {
  text-align: center !important;
  color: #FFF !important;
}

.blue, .lighten {
  background-color: #0E4666 !important;
}

.blue p, .lighten p {
  color: white !important;
  text-align: center !important;
}

.brown, .accumulate {
  background-color: #ffe699 !important;
}

.brown p, .accumulate p {
  text-align: center !important;
}

.gray {
  background-color: #E3E3E3 !important;
}

.red, .sell {
  background-color: #C9091A !important;
}

.red p, .sell p {
  color: white !important;
  text-align: center !important;
}

/*
* Medium screens styling for the Article page
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .article-hero-unit {
    margin-bottom: 30px;
  }
  .article-info {
    margin-bottom: 30px;
  }
}

/*
* Small screens styling for the Article page
*/
@media screen and (max-width: 39.9375em) {
  .article-info {
    margin-bottom: 30px;
  }
}
