/*
*  styling for the Invest  page
*/
.invest-page-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url("/site_images/new/buyinvest.jpg");
  background-position: center;
  height: 650px;
  background-size: auto 880px;
  background-repeat: no-repeat;
}

.invest-page-hero-info {
  text-align: center;
}

.invest-page-hero-info h2 {
  margin-top: 214px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 200;
}

.invest-page h2 {
  text-transform: uppercase;
}

.invest-page p {
  font-size: 14px;
}

.invest-intro {
  margin-top: 65px;
}

.invest-reason, .why-invest, .invest-intro {
  margin-bottom: 65px;
}

.invest-reason-img img {
  width: 100%;
  border-radius: 3px;
}

.why-invest-img img {
  height: 315px;
  width: 100%;
  border-radius: 3px;
}

.alma-buying-img img {
  height: 460px;
  width: 100%;
  margin-top: 109px;
}

.why-invest-content p {
  margin-bottom: 8px;
}

.alma-buying-content-plans, .why-invest-content-rates {
  margin-left: 30px;
}

.alma-buying-content-plans p {
  margin-bottom: 10px;
}

.alma-buying-content-plans h6 {
  margin-bottom: 0px;
  color: #912842;
}

/*
* Small screens styling for the Invest  page
*/
@media screen and (max-width: 39.9375em) {
  .invest-page-hero-info h2 {
    margin-top: 120px;
  }
  .invest-intro {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .invest-reason, .why-invest {
    margin-bottom: 30px;
  }
  .why-invest-content, .alma-buying-content {
    margin-bottom: 20px;
  }
  .invest-reason-content {
    margin-top: 20px;
  }
  .alma-buying-img img {
    margin-top: 10px;
  }
}

/*
* Medium screens styling for the Invest  page
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .invest-intro {
    margin-top: 30px;
  }
  .invest-reason, .why-invest, .invest-intro {
    margin-bottom: 30px;
  }
  .invest-reason, .why-invest {
    margin-bottom: 40px;
  }
  .why-invest-content, .alma-buying-content {
    margin-bottom: 20px;
  }
  .alma-buying-img img {
    margin-top: 10px;
  }
}
