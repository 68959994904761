/*
*  Book A Visit  page styling
*/
.booking-hero-unit {
  background-image: linear-gradient(rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url("/site_images/book_visit_banner.jpg");
  height: 365px;
  background-position: center;
  background-size: auto 400px;
  background-repeat: no-repeat;
}

.booking-hero-unit .hero-unit-info h2 {
  margin-top: 165px;
}

.booking-details {
  margin-top: 65px;
}

.booking-details h2 {
  position: relative;
}

.booking-details select {
  border-radius: 3px;
}

.booking-details h2:after {
  content: "";
  background: #949c9c;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 30%;
}

.booking-details h4, .booking-details .fa {
  color: #706c6c;
}

.booking-details-main .columns {
  left: -5px;
}

.booking-details p {
  margin: 0;
}

.booking-details-location {
  margin-bottom: 10px;
}

/*
* Medium screens styling for the  Book A Visit page
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .booking-details {
    margin-top: 30px;
  }
  .booking-details-main .columns {
    left: 0px;
  }
  .booking-details .contact-form {
    margin-top: 20px;
  }
  .booking-details h2:after {
    width: 10%;
  }
}

/*
* Small screens styling for the  Book A Visit   page
*/
@media screen and (max-width: 39.9375em) {
  .booking-details {
    margin-top: 30px;
  }
  .booking-details-main .columns {
    left: 0px;
  }
  .booking-details .contact-form {
    margin-top: 20px;
  }
  .booking-details h2:after {
    width: 10%;
  }
}
