/*
* Policy and Terms Page styling
*/

.privacy-hero-unit, .terms-hero-unit{
  background-image: linear-gradient(rgba(0, 0, 0, .48), rgba(0, 0, 0, .48)),
  url("/site_images/alma-home-page.jpg");
  background-position: center;
  height: 205px;
  background-size: auto 960px;
  background-repeat: no-repeat;
}

.policy-info ul li, .terms-info ul li{
  color: #706c6c;
}

.policy-info, .terms-info {
  margin-top: 65px;
  margin-bottom: 65px;
}


.policy-info h2, .terms-info h2 {
  margin-bottom: 30px;
}

.policy-subtitle {
  font-weight: 600;
}

.policy-info p, .terms-info p{
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
}

.policy-info li, .terms-info li {
  line-height: 24.5px;
}

/*
* Medium screens styling for the policy page
*/

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .policy-info, .terms-info {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .policy-info h2, .terms-info h2 {
    margin-bottom: 20px;
  }
}

/*
* Small screens styling for the policy page
*/
@media screen and (max-width: 39.9375em) {
  .policy-info, .terms-info {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .policy-info h2, .terms-info h2 {
    margin-bottom: 20px;
  }
}