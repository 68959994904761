.landing-container #boxholder {
  width: 1200px;
  margin: auto;
}

.landing-container .background-section-one {
  background-image: url("/landingpage_images/Alma-Main-Banner.jpg");
}

.landing-container .background-section-four {
  background-image: url("/landingpage_images/Alma-Second-Banner.jpg");
  background-attachment: fixed;
}

.landing-container .background-section-video {
  background-image: url("/landingpage_images/Alma-Video-Banner.jpg");
}

.landing-container #hfive-sectionone {
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold !important;
  font-size: 32px !important;
  letter-spacing: 2px;
  font-style: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
  margin-top: 30px;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.43);
  text-align: left !important;
  margin-bottom: .5rem;
  line-height: 1.4;
  text-transform: none !important;
}

.landing-container #contacts-form {
  margin-top: 70px;
}

.landing-container .logo {
  max-height: 89px !important;
  padding-top: 1px !important;
  margin-right: 115px;
  padding-bottom: 2px;
  margin-left: 0px !important;
}

.landing-container img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (min-width: 64.0625em) {
  .landing-container .large-3 {
    width: 25%;
  }
  .landing-container .large-5 .columns.book {
    width: 25%;
  }
}

@media screen and (min-width: 40em) {
  .landing-container #boxholder {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  #boxholder {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .landing-container .column, .landing-container .columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }
}

.landing-container .button {
  display: inline-block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-transition: background-color .25s ease-out, color .25s ease-out;
  transition: background-color .25s ease-out, color .25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 0;
  padding: .85em 1em;
  margin: 0 0 1rem;
  font-size: .9rem;
  background-color: #2199e8;
  color: #fefefe;
}

.landing-container #slick-dots li button:before {
  font-size: 20px !important;
  line-height: 10px !important;
  color: #7a7777 !important;
  opacity: 0.15 !important;
}

.landing-container .view-more-projects {
  color: #2199e8;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer;
}

.landing-container a {
  color: #2199e8;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer;
  background-color: transparent;
}

.landing-container .row {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

.landing-container .row.expanded {
  max-width: none;
}

.landing-container body {
  font-family: Quicksand;
}

.landing-container .button.hollow.secondary {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 2px;
  font-family: sans-serif;
  font-size: 12px;
  width: 160px;
}

.landing-container .va-align-logo {
  display: table-cell;
  vertical-align: top;
}

.landing-container .button.hollow.secondary:hover, .landing-container .button.hollow.secondary:focus {
  border-color: #006b5a;
  color: #fff;
  border-color: #ffffff;
  color: #545454;
  background: white;
}

.landing-container .button:hover, .landing-container .button:focus {
  border-color: #E67526;
  color: #fff;
  background-color: #E67526;
}

.landing-container h1, .landing-container h2, .landing-container h4, .landing-container h5, .landing-container h6 {
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 200;
  font-size: 29px;
  letter-spacing: 2px;
  font-style: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
  margin-top: 30px;
  margin-bottom: .5rem;
  line-height: 1.4;
  text-transform: none !important;
}

.landing-container .large-5.columns.book {
  padding-top: 10x;
  padding-bottom: 5px;
  text-align: right;
  color: #0c0b15;
}

.landing-container .display-table {
  display: table;
}

.landing-container .va-align {
  display: table-cell;
  vertical-align: middle;
  padding-top: 100px;
}

.landing-container .intro {
  background-size: cover;
  min-height: 500px;
  color: #ffffff;
  padding: 30px;
  text-align: center;
  background-position: bottom center;
}

.landing-container .large-3.columns.brief {
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: left;
}

.landing-container .button.hollow, .landing-container .button.hollow:focus, .landing-container .button.hollow:hover {
  background-color: transparent;
}

.landing-container #hfive {
  padding-top: 100px;
  padding-bottom: 100px;
}

.landing-container .price {
  background: linear-gradient(#212121, #000000);
  color: white;
  min-height: 200px;
}

.landing-container .price-links.row {
  height: 100px;
  width: 100%;
  padding-top: 0px;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .landing-container .description {
    width: 50%;
    padding: 25px;
    float: left;
  }
  .landing-container .unit-price {
    width: 50%;
    padding: 25px;
    float: right;
    align-content: right;
  }
  .landing-container #hfive {
    display: none;
  }
}

/* Medium and up */
@media screen and (min-width: 40em) {
  .landing-container .description {
    width: 75%;
    padding: 25px;
    float: left;
  }
  .landing-container .unit-price {
    width: 25%;
    padding: 25px;
    float: right;
    align-content: right;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .landing-container .description {
    width: 75%;
    padding: 25px;
    float: left;
  }
  .landing-container .unit-price {
    width: 25%;
    padding: 25px;
    float: right;
    align-content: right;
  }
}

.landing-container {
  /*submit*/
}

.landing-container .overview {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 200;
  margin-top: 20px;
  text-align: center;
}

.landing-container .overview h3 {
  font-weight: 200;
}

.landing-container .intro-video {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 25px;
  color: #ffffff;
  text-align: center;
  background-position: bottom center;
}

.landing-container .info {
  color: #fff;
  text-align: center;
}

.landing-container .background-section-four {
  background-attachment: fixed;
  min-height: 250px;
}

.landing-container .columns {
  padding-left: .0rem;
  padding-right: .0rem;
}

.landing-container .feature_pt {
  background-color: white;
  width: 80%;
  margin: auto;
  margin-top: -5 br0px;
  height: 60px;
  color: #121212 !important;
}

.landing-container li.large-2.show_feature {
  float: left;
  margin-left: 25px;
  padding: 10px;
  border-top: solid 1px #dcdcdc;
  margin-top: 5px;
  list-style: none;
}

.landing-container h3 {
  font-family: sans-serif;
  font-size: 29px;
  font-weight: 900;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.landing-container .overlay-container {
  width: 299px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
}

.landing-container .overlay-container img {
  height: 299px;
  padding-right: 5px;
  padding-left: 5px;
}

.landing-container .overlay-container .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.4rem;
  text-align: center;
  font: 0/0 a;
}

.landing-container .overlay-container .overlay:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.landing-container .overlay-container .overlay span {
  font: 16px/1 Arial, sans-serif;
  display: inline-block;
  vertical-align: middle;
}

.landing-container .overlay-fade-in .image-overlay-content {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.64);
  transition: all 0.4s ease-in-out;
}

.landing-container .overlay-fade-in p {
  letter-spacing: 0.15em;
  color: #f4f4f4;
  font-size: 14px;
  opacity: 0;
  position: relative;
  transition: all 0.2s linear;
}

.landing-container .overlay-fade-in img {
  transition: all 0.2s linear;
}

.landing-container .overlay-fade-in h2 {
  color: #f2f2f2;
  font-size: 1.8rem;
  margin-top: 1px;
  letter-spacing: normal;
  word-spacing: normal;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.landing-container .overlay-fade-in .button {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  background: #FFF;
  color: #222;
  text-transform: uppercase;
  box-shadow: 0 0 1px #000;
  position: relative;
  border: 1px solid #999;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.landing-container .image-overlay-content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.landing-container .overlay-fade-in .button:hover {
  box-shadow: 0 0 5px #000;
}

.landing-container .overlay-fade-in:hover img {
  transform: scale(1.2);
}

.landing-container .overlay-fade-in:hover .image-overlay-content {
  opacity: 1;
}

.landing-container .overlay-fade-in:hover h2, .landing-container .overlay-fade-in p, .landing-container .overlay-fade-in .button {
  opacity: 1;
}

.landing-container .overlay-fade-in:hover p {
  transition-delay: 0.1s;
}

.landing-container .overlay-fade-in:hover .button {
  transition-delay: 0.2s;
}

.landing-container .login-box-form-section p {
  font-family: sans-serif;
  width: 360px;
  font-size: 15px;
  font-weight: 100;
}

.landing-container b, .landing-container strong {
  font-weight: 700;
}

.landing-container .contacts-form {
  margin-top: 20px;
  background-color: #fff;
  color: #58585a;
  font-size: 22px;
  display: block;
  -moz-box-shadow: 0px 4px 9px 14px rgba(227, 224, 227, 0.51);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.51);
  border-bottom: solid 5px #a97501;
}

.landing-container .login-box-input {
  margin-bottom: 1.25rem;
  height: 2rem;
  border: 0;
  padding-left: 0;
  box-shadow: none;
  font-weight: 400;
  background: #f5f5f5;
}

.landing-container label {
  display: block;
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1;
  line-height: 1.8;
  font-family: sans-serif;
}

.landing-container input {
  line-height: normal;
  background: #f5f5f5 !important;
}

.landing-container .login-box-submit-button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0c0b15;
  color: #fefefe;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
  text-transform: uppercase;
  margin-bottom: 0;
}

.landing-container input.login-box-submit-button {
  font-family: sans-serif;
  background: linear-gradient(#5d5d5d, #000000) !important;
  border: none;
  font-weight: 800;
  height: 45px;
  letter-spacing: 2;
}

.landing-container .login-box {
  box-shadow: 0 2px 4px rgba(10, 10, 10, 0.4);
  background: #fefefe;
  border-radius: 0;
}

.landing-container .slick-slide {
  opacity: 1 !important;
}

.landing-container .login-box .or {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  text-align: center;
  font-size: 1.275rem;
  background: #cacaca;
  box-shadow: 0 2px 4px rgba(10, 10, 10, 0.4);
}

.landing-container .login-box-title {
  font-weight: 300;
  font-size: 1.875rem;
  margin-bottom: 1.25rem;
}

.landing-container .login-box-form-section,
.landing-container .login-box-social-section-inner {
  padding: 2.5rem;
}

.landing-container .login-box-social-section {
  background-size: cover;
  background-position: center;
}

.landing-container [data-whatinput='mouse'] .login-box-submit-button {
  outline: 0;
}

.landing-container .login-box-submit-button:hover,
.landing-container .login-box-submit-button:focus {
  box-shadow: 0 2px 4px rgba(10, 10, 10, 0.4);
}

.landing-container .login-box-submit-button:active {
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.4);
}

.landing-container .login-box-social-button-facebook {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #3b5998;
  color: #fefefe;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  font-weight: 500;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

.landing-container [data-whatinput='mouse'] .login-box-social-button-facebook {
  outline: 0;
}

.landing-container .login-box-social-button-facebook:hover, .landing-container .login-box-social-button-facebook:focus {
  background-color: #2f477a;
  color: #fefefe;
}

.landing-container [data-whatinput='mouse'] .login-box-social-button-twitter {
  outline: 0;
}

.landing-container .login-box-social-button-twitter:hover, .landing-container .login-box-social-button-twitter:focus {
  background-color: #1a8fe8;
  color: #fefefe;
}

.landing-container [class*="login-box-social-button-"]:hover,
.landing-container [class*="login-box-social-button-"]:focus {
  box-shadow: 0 2px 4px rgba(10, 10, 10, 0.4);
}

.landing-container .login-box-social-headline {
  display: block;
  margin-bottom: 2.5rem;
  font-size: 1.875rem;
  color: #fefefe;
  text-align: center;
}

.landing-container .footer {
  background-color: #000;
  text-align: center;
  padding: 10px;
  min-height: 220px;
}

.landing-container .vertical > li {
  display: block;
  font-family: sans-serif;
  font-size: 12px;
  color: #868686;
}

@media screen and (max-width: 39.9375em) {
  .landing-container #boxholder .login-box-social-section {
    display: none;
  }
  .landing-container #boxholder #contacts-form {
    margin-top: -60px;
  }
  .landing-container #boxholder a.view-more-projects {
    display: none;
  }
  .landing-container #boxholder #large-5 {
    display: none;
  }
}

@media screen and (max-width: 39.9375em) {
  .landing-container .login-box .or {
    top: 85%;
  }
  .landing-container .section-five-slider {
    display: none;
  }
  .landing-container .section-six {
    height: 316px;
  }
  .landing-container .background-section-four {
    background-image: none !important;
  }
}

@media screen and (max-width: 64.0625em) {
  .landing-container #boxholder {
    width: auto;
  }
  .landing-container #boxholder .large-8 {
    width: auto;
  }
  .landing-container #boxholder .large-4 {
    width: auto;
  }
  .landing-container #boxholder .book {
    margin-top: -50px;
  }
  .landing-container #boxholder .view-more-projects {
    padding-top: -5px;
  }
  .landing-container #boxholder .footer-links {
    margin-left: 22px;
    padding: 20px;
  }
  .landing-container #boxholder .footer {
    padding-top: 125px;
  }
  .landing-container #boxholder .price-links {
    padding-top: 1px;
    height: 100px;
  }
  .landing-container #boxholder h3 {
    line-height: 1;
  }
  .landing-container #boxholder p {
    width: auto;
  }
  .landing-container #boxholder .row.expanded {
    max-width: none;
  }
  .landing-container #boxholder li.large-2.show_feature {
    margin-left: 8px;
  }
  .landing-container #boxholder .section-six {
    height: auto;
  }
  .landing-container #boxholder .feature_pt {
    width: auto;
  }
  .landing-container #boxholder #hfive-sectionone {
    width: auto;
  }
  .landing-container #boxholder #h2 {
    font-size: 12px;
  }
  .landing-container #boxholder .h5 {
    font-size: 12px !important;
  }
}
