/*
* styling for the Floor Plans  page
*/
.floorplans-page h2 {
  text-transform: uppercase;
}

.floorplans-page h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #706c6c;
  font-size: 14px;
  font-weight: 200;
}

.floorplans-hero-unit .hero-unit-info {
  margin-top: -400px;
  z-index: 1000;
  position: relative;
}

.floorplans-hero-unit .reveal-modal {
  margin-top: 40px;
}

.floorplans-1br, .floorplans-3br {
  margin-bottom: 65px;
  margin-top: 65px;
}

.floorplans-2br {
  background-color: #F6F6F6;
  padding-bottom: 65px;
  padding-top: 65px;
}

.floorplans-3br-img img {
  height: 405px;
  width: 100%;
  margin-top: 100px;
}

.floorplans-1br-img img {
  height: 437px;
  width: 100%;
  margin-top: 80px;
}

.floorplans-2br-img img {
  height: 424px;
  width: 100%;
  margin-top: 70px;
}

.floorplans-details-img img {
  height: 165px;
  width: 100%;
}

.floorplans-details-img p {
  color: #912842;
}

.floorplans-1br .floorplans-details-img, .floorplans-2br .floorplans-details-img {
  margin-top: 60px;
}

.floorplans-3br .floorplans-details-header h2 {
  margin-bottom: 15px;
}

/*
* Medium screens styling for the  Floor Plans page
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .floorplans-1br, .floorplans-3br {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .floorplans-1br .floorplans-details, .floorplans-3br .floorplans-details {
    margin-top: 20px;
  }
  .floorplans-2br {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .floorplans-details-img img, .floorplans-main-img img {
    height: auto;
  }
  .floorplans-hero-unit .hero-unit-info {
    margin-top: -300px;
  }
  .floorplans-1br .floorplans-details-img, .floorplans-2br .floorplans-details-img {
    margin-top: 10px;
  }
}

/*
* Small screens styling for the  Floor Plans page
*/
@media screen and (max-width: 39.9375em) {
  .floorplans-1br, .floorplans-3br {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .floorplans-hero-unit .hero-unit-info {
    margin-top: -400px;
  }
  .floorplans-1br .floorplans-details, .floorplans-3br .floorplans-details {
    margin-top: 20px;
  }
  .floorplans-2br {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .floorplans-details-img img, .floorplans-main-img img {
    height: auto;
  }
  .floorplans-1br .floorplans-details-img, .floorplans-2br .floorplans-details-img {
    margin-top: 10px;
  }
  .floorplans-1br-img img, .floorplans-2br-img img, .floorplans-3br-img img {
    margin-top: 10px;
  }
}
