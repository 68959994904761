/*
*  Styling for the Lifestyle  page
*/

.lifestyle-hero-unit{
  background-image: linear-gradient(rgba(0, 0, 0, .48), rgba(0, 0, 0, .48)),
  url("/site_images/new/lifestyle.jpg");
  background-position: center;
  height: 650px;
  background-size: auto 840px;
  background-repeat: no-repeat;
}

.lifestyle-intro {
  margin-top: 65px;
}

.lifestyle-intro, .lifestyle-amenities, .lifestyle-banner, .lifestyle-slider {
  margin-bottom: 65px;
}

.lifestyle-intro-img img {
  width: 100%;
}

.lifestyle-amenities-content img {
  height: 203px;
  width: 100%;
}

.lifestyle-banner {
    background-image: linear-gradient(rgba(0, 0, 0, .48), rgba(0, 0, 0, .48)),
    url("/site_images/alma-home-page.jpg");
    background-position: center;
    height: 650px;
    background-size: auto 960px;
    background-repeat: no-repeat;
  }

.lifestyle-banner-content h1 {
  margin-top: 135px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 26px;
  color: #9b9b67;
  position: relative;
}

.lifestyle-banner-content h1:after {
  content: "";
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 57%;
}

.lifestyle-banner-content span {
  color: #fff;
}

.lifestyle-banner-content p {
  margin-bottom: 190px;
  color: #fff;
}

/*
* Small screens styling for the Lifestyle  page
*/
@media screen and (max-width: 39.9375em) {
  .lifestyle-intro {
    margin-top: 30px;
  }

  .lifestyle-intro, .lifestyle-amenities, .lifestyle-banner, .lifestyle-slider {
    margin-bottom: 30px;
  }

  .lifestyle-banner-content h1:after {
    width: 40%;
  }

  .lifestyle-intro-img img, .lifestyle-amenities-content img, .lifestyle-slider-img img {
    height: auto;
  }

  .lifestyle-amenities-content img{
    margin-bottom: 20px;
  }

}

/*
* Medium screens styling for the Lifestyle  page
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .lifestyle-intro {
    margin-top: 30px;
  }

  .lifestyle-intro, .lifestyle-amenities, .lifestyle-banner, .lifestyle-slider {
    margin-bottom: 30px;
  }

  .lifestyle-banner-content h1:after {
    width: 28%;
  }

  .lifestyle-intro-img img, .lifestyle-amenities-content img, .lifestyle-slider-img img {
    height: auto;
  }
}
