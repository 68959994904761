/*
* Floor plans background slider
*/

.cb-slideshow,
.cb-slideshow:after {
  position: relative;
  width: 100%;
  height: 650px;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style-type: none;
  margin-left: 0px;
}

.cb-slideshow li span {
  width: 100%;
  height: 650px;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 36s linear infinite 0s;
}

.cb-slideshow li div {
  z-index: 1000;
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  animation: titleAnimation 36s linear infinite 0s;
}

.cb-slideshow li:nth-child(1) span {
  background-image: url(/site_images/floorplans_banner_slider1.jpg)
}

.cb-slideshow li:nth-child(2) span {
  background-image: url(/site_images/floorplans_banner_slider2.jpg);
  animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) span {
  background-image: url(/site_images/floorplans_banner_slider3.jpg);
  animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) span {
  background-image: url(/site_images/floorplans_banner_slider4.jpg);
  animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) span {
  background-image: url(/site_images/floorplans_banner_slider5.jpg);
  animation-delay: 12s;
}

.cb-slideshow li:nth-child(2) div {
  animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) div {
  animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) div {
  animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) div {
  animation-delay: 24s;
}


/* Animation for the slideshow images */
@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1
  }
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


