/* Footer Style*/
.footer {
  background-color: #912842;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 25px;
}

.footer h2 {
  font-size: 20px;
  line-height: 48px;
  color: #fff;
}

.footer p {
  color: #c4c4c4;
}

.footer-social a {
  color: #fff;
}

.footer-social a:hover {
  color: #008CBA;
}

.footer-main {
  border-bottom: 1px solid #fff;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

.footer-contact-icons p {
  margin: 0;
  color: #fff;
}

/*  Icons */
.footer .fa {
  color: #9b9b67;
}

.footer .fa:hover {
  color: #fff;
}

.footer-social .fa {
  color: #fff;
}

.footer-social .fa:hover {
  color: #9b9b67;
}

.footer-social .fa-twitter {
  margin-right: 10px;
}

.footer-contact-icons .fa-map-marker {
  margin-right: 14px;
}

.footer-contact-icons .fa-envelope {
  margin-right: 9px;
}

.footer-contact-icons .fa-phone {
  margin-right: 10px;
}

.footer-subscribe .button {
  background: #9b9b67;
  border-radius: 3px;
  padding-top: 8px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: initial;
}

@media screen and (max-width: 39.9375em) {
  .footer {
    padding-top: 20px;
  }
  .footer-main {
    padding-bottom: 30px;
    margin-bottom: 25px;
  }
}
