/*
* Leads Page Styling
*/
.leads-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), url("/site_images/leads_banner.jpg");
  background-position: center;
  height: 365px;
  background-size: auto 400px;
  background-repeat: no-repeat;
}

.leads-hero-unit-info h2 {
  margin-top: 150px;
  color: #fff;
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 100;
  letter-spacing: 1px;
}

.leads-details {
  margin-top: 65px;
  margin-bottom: 65px;
}

.leads-details .fa-circle {
  color: #9b9b67;
  font-size: 50px;
}

.leads-details .fa-mobile, .leads-details .fa-envelope, .leads-details .fa-map-marker {
  color: #fff;
  font-size: 20px;
  margin-top: 7px;
  margin-left: 5px;
}

.leads-details .fa-stack {
  margin-bottom: 20px;
}

.leads-telephone, .leads-email {
  margin-left: 45px;
}

.leads-details-title h2 {
  color: black;
  text-align: center;
}

.leads-details-title h2 span, .office {
  color: #912842;
}

.leads-address .second-office {
  margin-top: 15px;
}

.leads-address p, .leads-telephone p, .leads-email p {
  margin-bottom: 1px;
}

.leads-form {
  margin-bottom: 65px;
}

.leads-form-details input[type=text], .leads-form-details input[type=email], .leads-form-details textarea {
  background-color: #f8f8f8;
}

.leads-form-details .button {
  background-color: #9b9b67;
  border-radius: 3px;
}

.leads-form-title h2 {
  color: #484848;
  text-align: center;
}

.leads-form-title h2 span {
  color: #912842;
}

.leads-link {
  color: #484848;
}

.leads-current :hover {
  background-color: #912842;
  color: #fff;
}

ul {
  list-style-type: none;
}

/*
* Small screens styling for the   page
*/
@media screen and (max-width: 39.9375em) {
  .leads-details {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .leads-form {
    margin-bottom: 30px;
  }
  .leads-address, .leads-telephone, .leads-email {
    margin-bottom: 20px;
  }
  .leads-telephone, .leads-email {
    margin-left: 0px;
  }
}

/*
* Medium screens styling for the   page
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .leads-address, .leads-telephone, .leads-email {
    margin-bottom: 20px;
  }
}
